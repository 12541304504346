<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        Add Accreditation
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form
        ref="form"
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
      >
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Accreditation Name"
              label-for="blog-edit-title"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Accreditation Name"
                rules="required"
              >
                <b-form-input
                data-testid="accreditation-name"
                  v-model="addCourseForm.name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Accreditation Website Link"
              label-for="blog-edit-slug"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Accreditation Website Link"
                rules="required"
              >
                <b-form-input
                data-testid="accreditation-link"
                  v-model="addCourseForm.link"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mb-2"
          >
            <div class="border rounded p-2">
              <h4 class="mb-1">
                Accreditation Logo
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <b-media-aside>
                  <b-img
                    ref="refPreviewE2"
                    :src="Tabimage"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-media-body>
                  <b-card-text class="my-50" />
                  <div class="d-inline-block">
                    <b-form-group
                      label="Tab Image"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tab Image"
                        rules="required"
                      >
                        <b-form-file
                        data-testid="accreditation-logo"
                          ref="refInputE2"
                          v-model="addCourseForm.image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Tab Image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="addCourseForm.alt_image"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>
          <b-col
            cols="12"
            class="mb-2"
          >
            <div class="border rounded p-2">
              <h4 class="mb-1">
                Image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <b-media-aside>
                  <b-img
                    ref="refPreviewEl"
                    :src="Tabimage"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-media-body>
                  <b-card-text class="my-50" />
                  <div class="d-inline-block">
                    <b-form-group
                      label="Image"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Image"
                        rules="required"
                      >
                        <b-form-file
                        data-testid="accreditation-image"
                          ref="refInputEl"
                          v-model="addCourseForm.certificate"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRenderer"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Description"
              label-for="blog-content"
              class="mb-2"
              id="accreditation-description"
            >
              <validation-provider
                #default="{ errors }"
                name="Description"
                rules=""
              >
                <quill-editor
                  ref="descriptionEditor"
                  v-model="addCourseForm.description"
                  :options="getEditorOptions('descriptionEditor')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
            data-testid="accreditation-submit-button"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
        <b-modal id="image-properties-modal" title="Image Properties">
      <b-form>
        <b-form-group label="Alt Text">
          <b-form-input v-model="imageProperties.alt"></b-form-input>
        </b-form-group>
        <b-form-group label="Width">
          <b-form-input v-model="imageProperties.width" placeholder="e.g., 100px or 50%"></b-form-input>
        </b-form-group>
        <b-form-group label="Height">
          <b-form-input v-model="imageProperties.height" placeholder="e.g., 100px or auto"></b-form-input>
        </b-form-group>
        <b-form-group label="Alignment">
          <b-form-select v-model="imageProperties.alignment">
            <option value="">None</option>
            <option value="ql-align-left">Left</option>
            <option value="ql-align-center">Center</option>
            <option value="ql-align-right">Right</option>
          </b-form-select>
        </b-form-group>
        <b-button variant="primary" @click="insertImage">Insert</b-button>
      </b-form>
    </b-modal>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive, getCurrentInstance } from '@vue/composition-api'
import Vue from 'vue'
import { quillEditor } from 'vue-quill-editor'
import store from '@/store'
import 'quill/dist/quill.snow.css'
// Import Quill and define custom size style
import Quill from 'quill'
const SizeStyle = Quill.import('attributors/style/size')
SizeStyle.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '32px', '36px', '48px', '60px', '72px', '96px']
Quill.register(SizeStyle, true)

// Create a custom Image format
const BlockEmbed = Quill.import('blots/block/embed')
class ImageFormat extends BlockEmbed {
  static create(value) {
    let node = super.create(value)
    node.setAttribute('src', value.src)
    node.setAttribute('alt', value.alt || '')
    node.setAttribute('width', value.width || '')
    node.setAttribute('height', value.height || '')
    node.setAttribute('class', value.class || '')
    return node
  }

  static value(node) {
    return {
      src: node.getAttribute('src'),
      alt: node.getAttribute('alt'),
      width: node.getAttribute('width'),
      height: node.getAttribute('height'),
      class: node.getAttribute('class'),
    }
  }
}
ImageFormat.blotName = 'image'
ImageFormat.tagName = 'img'
Quill.register(ImageFormat, true)

export default {
  setup() {
    const refInputEl = ref(null)
    const refInputE2 = ref(null)
    const refPreviewEl = ref(null)
    const refPreviewE2 = ref(null)
    const Tabimage = ref('media/svg/files/blank-image.svg')
    const { proxy } = getCurrentInstance()

    const addCourseForm = reactive({
      link: '',
      name: '',
      image: '',
      alt_image: '',
      description: '',
      certificate: '',
    })

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      refPreviewEl.value.src = base64
    })
    const { inputImageRendererTab } = useInputImageRenderer(refInputE2, base64 => {
      refPreviewE2.value.src = base64
    })

    const addCourseFormvalidate = ref()
    const form = ref()

    const save = () => {
      console.log(addCourseForm)
      const formData = new FormData()

      addCourseFormvalidate.value.validate().then(success => {
        if (success) {
          formData.append('image', addCourseForm.image)
          formData.append('name', addCourseForm.name)
          formData.append('link', addCourseForm.link)
          formData.append('certificate', addCourseForm.certificate)
          formData.append('alt_image', addCourseForm.alt_image)
          formData.append('description', addCourseForm.description)
          store.dispatch('accreditations/addAccreditations', formData)
            .then(response => {
              form.value.reset()
              refPreviewEl.value.src = 'media/svg/files/blank-image.svg'
              refPreviewE2.value.src = 'media/svg/files/blank-image.svg'
              Vue.swal({
                title: 'Accreditations Added',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }).catch(error => {
              console.log(error.response.data)
              Vue.swal({
                title: '',
                text: `${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }

    const imageProperties = reactive({
      src: '',
      alt: '',
      width: '',
      height: '',
      alignment: '',
    })
    let editorSelection = null
    let currentEditor = null

    const imageHandler = editorRef => {
      currentEditor = proxy.$refs[editorRef].quill
      editorSelection = currentEditor.getSelection()
      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'image/*')
      input.click()

      input.onchange = async () => {
        const file = input.files[0]
        const formData = new FormData()
        formData.append('image', file)

        try {
          const token = localStorage.getItem('token')
          const xhr = new XMLHttpRequest()
          xhr.withCredentials = false
          xhr.open('POST', `${process.env.VUE_APP_ROOT_API}/upload_image_tiny_mce`)
          xhr.setRequestHeader('Authorization', `Bearer ${token}`)

          xhr.onload = function () {
            if (xhr.status === 403) {
              console.error(`HTTP Error: ${xhr.status}`)
              return
            }

            if (xhr.status < 200 || xhr.status >= 300) {
              console.error(`HTTP Error: ${xhr.status}`)
              return
            }

            const json = JSON.parse(xhr.responseText)

            if (!json || typeof json.location !== 'string') {
              console.error(`Invalid JSON: ${xhr.responseText}`)
              return
            }

            imageProperties.src = json.location
            proxy.$bvModal.show('image-properties-modal')
          }

          xhr.onerror = function () {
            console.error(`Image upload failed due to a XHR Transport error. Code: ${xhr.status}`)
          }

          xhr.send(formData)
        } catch (error) {
          console.error('Image upload failed:', error)
        }
      }
    }

    const insertImage = () => {
      const editor = currentEditor
    console.log(currentEditor.getSelection())
      const range = editorSelection
      if (!range) {
        console.error('No selection found')
        return
      }
      const { src, alt, width, height, alignment } = imageProperties
  
      editor.insertEmbed(range.index, 'image', { src, alt, width, height, class: alignment })
      proxy.$bvModal.hide('image-modal-post')
         imageProperties.src = '';
      imageProperties.alt = '';
      imageProperties.width = '';
      imageProperties.height = '';
      imageProperties.alignment = '';
    }


    const linkHandler = editorRef => {
      console.log("ddddddd")
      const range = proxy.$refs[editorRef].quill.getSelection()
      if (range) {
        const url = prompt('Enter the URL')
        if (url) {
          const target = confirm('Open in new tab?') ? '_blank' : '_self'
          const linkValue = `<a href="${url}" target="${target}">${proxy.$refs[editorRef].quill.getText(range)}</a>`
          proxy.$refs[editorRef].quill.clipboard.dangerouslyPasteHTML(range.index, linkValue)
        }
      }
    }

    const getEditorOptions = editorRef => ({
      theme: 'snow',
      modules: {
        toolbar: {
          container: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['link', 'image', 'video'],
            [{ size: SizeStyle.whitelist }],
    ['bold', 'italic', 'underline', 'strike'],
  
    ['clean'],
    ['undo', 'redo'],
    ['paragraph']
          ],
          handlers: {
            image: () => imageHandler(editorRef),
              
              link: () => linkHandler(editorRef)
              
          // Pass the editor reference
          },
        },
      },
    })

    return {
      refInputEl,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      addCourseForm,
      inputImageRenderer,
      addCourseFormvalidate,
      form,
      save,
      required,
       imageHandler,
      insertImage,
      imageProperties,
      linkHandler,
      getEditorOptions,
    }
  },
  components: {
    BCard,
    BMedia,
    quillEditor,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
  },
  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>

<style>
.mb-2.col-12 {
  margin: -1px !important;
}
.border.rounded.p-2 {
  margin: 8px 1px !important;
}
.col-12 {
  margin: 0px;
}
.quill-editor {
  margin-left: 1px;
  width: 100%;
}
</style>
